import { useState, useEffect, useCallback } from 'react';
import useApi from '../utils/useApi';

const PUSH_SUBSCRIPTION_KEY = 'push_notification_subscription';
const PUSH_PERMISSION_KEY = 'push_notification_permission';


const useNotifications = () => {
    const [platform, setPlatform] = useState('unknown');
    const [registration, setRegistration] = useState(null);
    const [isInitialized, setIsInitialized] = useState(false);
    const [error, setError] = useState(null);
    const api = useApi();

    const [permission, setPermission] = useState(() => {
        try {
            const savedPermission = localStorage.getItem(PUSH_PERMISSION_KEY);
            return savedPermission || 'default';
        } catch (err) {
            return 'default';
        }
    });

    const [subscription, setSubscription] = useState(() => {
        try {
            const savedSubscription = localStorage.getItem(PUSH_SUBSCRIPTION_KEY);
            return savedSubscription ? JSON.parse(savedSubscription) : null;
        } catch (err) {
            console.error('Error loading subscription from localStorage:', err);
            return null;
        }
    });

    // Save subscription to localStorage whenever it changes
    useEffect(() => {
        if (subscription) {
            try {
                localStorage.setItem(PUSH_SUBSCRIPTION_KEY, JSON.stringify(subscription));
            } catch (err) {
                console.error('Error saving subscription to localStorage:', err);
            }
        } else {
            localStorage.removeItem(PUSH_SUBSCRIPTION_KEY);
        }
    }, [subscription]);

    // Save permission to localStorage whenever it changes
    useEffect(() => {
        if (permission !== 'default') {
            localStorage.setItem(PUSH_PERMISSION_KEY, permission);
        }
    }, [permission]);

    // Detect platform and version
    useEffect(() => {
        const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
        const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

        if (isIOS && isSafari) {
            // Extract iOS version
            const match = navigator.userAgent.match(/OS (\d+)_/);
            const iOSVersion = match ? parseInt(match[1], 10) : 0;

            if (iOSVersion >= 16) {
                setPlatform('ios-safari-supported');
            } else {
                setPlatform('ios-safari-unsupported');
            }
        } else {
            setPlatform('other');
        }
    }, []);

    const urlBase64ToUint8Array = useCallback((base64String) => {
        if (!base64String) {
            throw new Error('VAPID public key is not defined');
        }
        const padding = '='.repeat((4 - base64String.length % 4) % 4);
        const base64 = (base64String + padding)
            .replace(/\-/g, '+')
            .replace(/_/g, '/');

        const rawData = window.atob(base64);
        const outputArray = new Uint8Array(rawData.length);

        for (let i = 0; i < rawData.length; ++i) {
            outputArray[i] = rawData.charCodeAt(i);
        }
        return outputArray;
    }, []);

    const checkServiceWorkerSupport = useCallback(() => {
        if (platform === 'ios-safari-unsupported') {
            setError('Push notifications require iOS 16.4 or later');
            return false;
        }

        if (!('serviceWorker' in navigator)) {
            setError('Service Workers are not supported in this browser');
            return false;
        }
        if (!('PushManager' in window)) {
            setError('Push notifications are not supported in this browser');
            return false;
        }
        return true;
    }, [platform]);

    const checkSubscription = useCallback(async (reg) => {
        console.log(reg);
        if (!reg || !reg.pushManager) {
            console.error('Push manager not available');
            return;
        }

        console.error('CHECKING SUBSCRIPTION');

        try {
            const response = await reg.pushManager.getSubscription();
            console.log(response);
            console.log('Current push subscription:', response);
            setSubscription(response);
            if (Notification.permission === 'granted') {
                setPermission('granted');
            }
        } catch (error) {
            console.error('Error checking subscription:', error);
        }
    }, []);

    const registerServiceWorker = useCallback(async () => {
        try {
            // First check if service worker is already registered
            const existingRegistrations = await navigator.serviceWorker.getRegistrations();
            const existingSW = existingRegistrations.find(reg =>
                                                              reg.active && reg.active.scriptURL.includes('/service-worker.js')
            );

            if (existingSW) {
                console.log('Service Worker already registered:', existingSW);
                setRegistration(existingSW);
                await checkSubscription(existingSW);
                return existingSW;
            }

            // Only unregister if we need to and platform isn't iOS Safari
            if (platform !== 'ios-safari-supported' && existingRegistrations.length > 0) {
                console.log('Cleaning up old service worker registrations');
                for (let reg of existingRegistrations) {
                    await reg.unregister();
                }
            }

            const swPath = '/service-worker.js';
            console.log('Registering new service worker at:', swPath);

            const newRegistration = await navigator.serviceWorker.register(swPath, {
                scope: '/',
                type: 'module'
            });

            console.log('Service Worker registered successfully:', newRegistration);
            await navigator.serviceWorker.ready;
            console.log('Service Worker is ready');

            setRegistration(newRegistration);
            await checkSubscription(newRegistration);

            return newRegistration;
        } catch (err) {
            console.error('Service Worker registration failed:', err);
            throw err;
        }
    }, [platform, checkSubscription]);

    const subscribeUser = useCallback(async () => {
        try {
            let currentRegistration = registration;

            if (!currentRegistration?.pushManager) {
                currentRegistration = await registerServiceWorker();
            }

            // iOS Safari requires user interaction to request permission
            console.log('Requesting notification permission...');
            const permissionResult = await Notification.requestPermission();
            console.log(permissionResult);
            setPermission(permissionResult);

            if (permissionResult === 'granted') {
                if (!process.env.REACT_APP_VAPID_PUBLIC_KEY) {
                    throw new Error('VAPID public key is not configured');
                }

                const subscribeOptions = {
                    userVisibleOnly: true,
                    applicationServerKey: urlBase64ToUint8Array(process.env.REACT_APP_VAPID_PUBLIC_KEY)
                };

                console.log('Subscribing to push notifications...');
                const pushSubscription = await currentRegistration.pushManager.subscribe(subscribeOptions);
                console.log('Successfully subscribed to push:', pushSubscription);

                setSubscription(pushSubscription);

                // Send the subscription to your server
                const response = await api.subscribeToPushNotifications(pushSubscription);
                console.log(response);
                return response;
            }
            return {
                success: 'failedPermission',
                message: 'Failed. Please enable notification permission',
                permission: permissionResult,

            };
        } catch (err) {
            console.error('Failed to subscribe user:', err);
            setError(err.message);
            return false;
        }
    }, [registration, registerServiceWorker, urlBase64ToUint8Array, api]);

    const unsubscribeUser = useCallback(async () => {
        if (subscription) {
            try {
                await subscription.unsubscribe();
                const response = await api.unsubscribeFromPushNotifications(subscription);
                setSubscription(null);

                return response;
            } catch (error) {
                console.error('Error unsubscribing:', error);

                return false;
            }
        }
    }, [subscription, api]);

    useEffect(() => {
        let mounted = true;

        const initialize = async () => {
            if (!checkServiceWorkerSupport()) return;

            try {
                if (mounted) {
                    if(!isInitialized){
                        await registerServiceWorker();
                        setIsInitialized(true);
                    }
                }
            } catch (error) {
                if (mounted) {
                    console.error('Failed to initialize notifications:', error);
                    setError(error.message);
                }
            }
        };

        if (platform !== 'unknown') {
            initialize();
        }

        return () => {
            mounted = false;
            if (registration) {
                setRegistration(null);
                setIsInitialized(false);
            }
        };
    }, [platform, checkServiceWorkerSupport, registerServiceWorker, isInitialized]);

    return {
        platform,
        permission,
        subscription,
        subscribeUser,
        unsubscribeUser,
        isInitialized,
        error,
        registration
    };
};

export default useNotifications;
