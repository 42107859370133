import React, { createContext, useState, useContext, useCallback, useEffect, useRef } from 'react';
import GeolocationManager from '../utils/geolocation';
import useApi from "../utils/useApi";
import authApi from '../utils/auth.api';
import { stateProvinceAbbreviations } from './stateProvinceAbbreviations';


const GeolocationContext = createContext();

export const GeolocationProvider = ({ children }) => {
    const [geoposition, setGeoposition] = useState(null);
    const [dateObject, setDateObject] = useState(null);
    const [locationLoading, setLocationLoading] = useState(false);
    const { getGeoposition, updateRecentLocation, isLoading, error } = useApi();
    const locationChangeTimeoutRef = useRef(null);


    const getIpGeoPosition = async () => {
        const results = await getGeoposition();
        localStorage.setItem('dateObject', JSON.stringify(results));
        setDateObject(results);
        return results;
    };

    const changeLocation = useCallback(async (input) => {
        console.log("Changing location to:", input);

        if (locationChangeTimeoutRef.current) {
            clearTimeout(locationChangeTimeoutRef.current);
        }

        locationChangeTimeoutRef.current = setTimeout(async () => {
            try {
                setLocationLoading(true);
                const response = await fetch(
                    `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(input)}&limit=1`
                );
                const data = await response.json();

                setLocationLoading(false);
                if (data.length > 0) {
                    const location = data[0];
                    const addressParts = location.display_name.split(', ');
                    const newGeo = {
                        latitude: parseFloat(location.lat),
                        longitude: parseFloat(location.lon),
                        city: location.name,
                        state: addressParts[addressParts.length - 2],
                        state_abbr: stateProvinceAbbreviations[addressParts[addressParts.length - 2]] || addressParts[addressParts.length - 2],
                        country: addressParts[addressParts.length - 1],
                        country_code: addressParts[addressParts.length - 1] === "United States" ? "US" : "Unknown",
                    };

                    const searchParams = new URLSearchParams(location.search);
                    searchParams.set('city', newGeo.city + ',' + newGeo.state_abbr);
                    window.history.replaceState({}, '', `${window.location.pathname}?${searchParams.toString()}`);

                    setGeoposition(newGeo);
                    localStorage.setItem('geoposition', JSON.stringify(newGeo));
                    localStorage.setItem('userGeolocation', JSON.stringify(newGeo));
                    await updateRecentLocation(newGeo);
                }
            } catch (error) {
                console.error("Error changing location:", error);
            }
        }, 1000);
    }, [updateRecentLocation]);

    const changeGeoPosition = async (skipSaved = false, selectedLocation = null) => {
        let allGeo = {};

        console.log(selectedLocation);
        try {
            const currentUser = authApi.getCurrentUser();

            if (selectedLocation) {
                setGeoposition(selectedLocation);
                localStorage.setItem('geoposition', JSON.stringify(selectedLocation));
                await updateRecentLocation(selectedLocation);

                handleLocationHistoryChange(selectedLocation);

                if (document.activeElement) {
                    document.activeElement.blur();
                }
            } else {
                const savedGeoposition = localStorage.getItem('geoposition');

                if (savedGeoposition && !skipSaved) {
                    console.log('Using Same location');
                    setGeoposition(JSON.parse(savedGeoposition));
                } else {
                    console.log('Using New location');

                    const geo = GeolocationManager;
                    const locationData = await geo.getLocation({newLocation: true});
                    console.log(locationData);
                    allGeo.latitude = locationData.latitude;
                    allGeo.longitude = locationData.longitude;

                    const response = await fetch(
                        `https://nominatim.openstreetmap.org/reverse?format=json&lat=${locationData.latitude}&lon=${locationData.longitude}`
                    );
                    const data = await response.json();

                    allGeo.city = data.address.city || data.address.town || data.address.village;
                    allGeo.state = data.address.state;
                    allGeo.state_abbr = stateProvinceAbbreviations[data.address.state] || data.address.state;
                    allGeo.country = data.address.country;
                    allGeo.country_code = data.address.country_code.toUpperCase();

                    setGeoposition(allGeo);
                    localStorage.setItem('geoposition', JSON.stringify(allGeo));
                    await updateRecentLocation(allGeo);
                    console.log(allGeo);
                }
            }
        } catch (error) {
            console.error("Error in fetchData:", error);
            allGeo = await getIpGeoPosition();
            setGeoposition(allGeo);
            localStorage.setItem('geoposition', JSON.stringify(allGeo));
        }

        const dateObject = await getIpGeoPosition();
        setDateObject(dateObject);
    };

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const city = searchParams.get('city') || null;
        console.log(searchParams.get('city'));
        const fetchData = async () => {
            await changeGeoPosition();
        };

        if(city){
            console.log('cityRef')
            handleCityRef(city, searchParams);
        } else {
            fetchData();
        }


    }, []);

    const handleLocationHistoryChange = (newGeo) => {
        const searchParams = new URLSearchParams(location.search);
        searchParams.set('city', newGeo.city + ',' + newGeo.state_abbr);
        window.history.replaceState({}, '', `${window.location.pathname}?${searchParams.toString()}`);
    }


    const handleCityRef = async (city, searchParams) => {
        changeLocation(city);
    }

    const value = {
        geoposition,
        dateObject,
        locationLoading,
        isLoading,
        error,
        changeLocation,
        changeGeoPosition
    };

    return (
        <GeolocationContext.Provider value={value}>
            {children}
        </GeolocationContext.Provider>
    );
};

export const useGeolocation = () => {
    const context = useContext(GeolocationContext);
    if (context === undefined) {
        throw new Error('useGeolocation must be used within a GeolocationProvider');
    }
    return context;
};
