import React, { createContext, useState, useContext } from 'react';

// Create the context
const HighlightDispensaryContext = createContext();

// Create a provider component
export const HighlightDispensaryProvider = ({ children }) => {
    const [highlightedDispensaryId, setHighlightedDispensaryId] = useState(null);

    return (
        <HighlightDispensaryContext.Provider
            value={{ highlightedDispensaryId, setHighlightedDispensaryId }}
        >
            {children}
        </HighlightDispensaryContext.Provider>
    );
};

// Create a custom hook to use the context
export const useHighlightDispensary = () => {
    const context = useContext(HighlightDispensaryContext);
    if (!context) {
        throw new Error('useHighlightDispensary must be used within a HighlightDispensaryProvider');
    }
    return context;
};
