import React, { createContext, useContext, useState, useEffect } from 'react';
import useApi from "../utils/useApi";


const UserPreferencesContext = createContext();

export const UserPreferencesProvider = ({ children }) => {
    const api = useApi();
    const [preferences, setPreferences] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    // Fetch user preferences on mount
    useEffect(() => {
        const fetchPreferences = async () => {
            try {
                setIsLoading(true);
                const response = await api.getUserPreferences();
                setPreferences(response);
                setError(null);
            } catch (err) {
                setError(err.message);
                console.error('Error fetching user preferences:', err);
            } finally {
                setIsLoading(false);
            }
        };

        fetchPreferences();
    }, []);

    const value = {
        preferences,
        isLoading,
        error,
    };

    return (
        <UserPreferencesContext.Provider value={value}>
            {children}
        </UserPreferencesContext.Provider>
    );
};

// Custom hook to use the preferences context
export const useUserPreferences = () => {
    const context = useContext(UserPreferencesContext);
    if (context === undefined) {
        throw new Error('useUserPreferences must be used within a UserPreferencesProvider');
    }
    return context;
};

export default UserPreferencesContext;
