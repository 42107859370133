import React, {useCallback, useEffect, useState} from "react";

export const useDragScroll = () => {
    const [node, setNode] = useState(null);
    const [isDragging, setIsDragging] = useState(false);

    const ref = useCallback((nodeEle) => {
        setNode(nodeEle);
    }, []);

    const handleMouseDown = useCallback((e) => {
        if (!node) {
            return;
        }

        // Track the initial position to detect if this is a drag or a click
        const initialX = e.clientX;
        const initialY = e.clientY;
        const dragThreshold = 5; // pixels of movement required to consider it a drag
        let hasDragged = false;

        const startPos = {
            left: node.scrollLeft,
            top: node.scrollTop,
            x: e.clientX,
            y: e.clientY,
        };

        const handleMouseMove = (e) => {
            const dx = Math.abs(e.clientX - initialX);
            const dy = Math.abs(e.clientY - initialY);

            // If movement exceeds threshold, consider it a drag
            if (dx > dragThreshold || dy > dragThreshold) {
                e.preventDefault();
                e.stopPropagation();
                hasDragged = true;
                setIsDragging(true);

                // Update the scroll position
                const moveX = e.clientX - startPos.x;
                const moveY = e.clientY - startPos.y;
                node.scrollTop = startPos.top - moveY;
                node.scrollLeft = startPos.left - moveX;
                updateCursor(node);
            }
        };

        const handleMouseUp = (e) => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
            resetCursor(node);

            // If we dragged the content, prevent the click event from firing
            if (hasDragged) {
                e.preventDefault();
                e.stopPropagation();

                // This is crucial: we need to intercept the click event that will fire after mouseup
                const preventClickPropagation = (clickEvent) => {
                    clickEvent.preventDefault();
                    clickEvent.stopPropagation();
                    document.removeEventListener('click', preventClickPropagation, true);
                };

                // Add the listener with capture=true to catch the event in the capture phase
                document.addEventListener('click', preventClickPropagation, true);

                // Reset dragging state after a short delay
                setTimeout(() => {
                    setIsDragging(false);
                }, 100);
            }
        };

        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
    }, [node]);

    const handleTouchStart = useCallback((e) => {
        if (!node) {
            return;
        }

        const touch = e.touches[0];
        const initialX = touch.clientX;
        const initialY = touch.clientY;
        const dragThreshold = 5;
        let hasDragged = false;

        const startPos = {
            left: node.scrollLeft,
            top: node.scrollTop,
            x: touch.clientX,
            y: touch.clientY,
        };

        const handleTouchMove = (e) => {
            const touch = e.touches[0];
            const dx = Math.abs(touch.clientX - initialX);
            const dy = Math.abs(touch.clientY - initialY);

            if (dx > dragThreshold || dy > dragThreshold) {
                e.preventDefault();
                e.stopPropagation();
                hasDragged = true;
                setIsDragging(true);

                const moveX = touch.clientX - startPos.x;
                const moveY = touch.clientY - startPos.y;
                node.scrollTop = startPos.top - moveY;
                node.scrollLeft = startPos.left - moveX;
                updateCursor(node);
            }
        };

        const handleTouchEnd = (e) => {
            document.removeEventListener('touchmove', handleTouchMove);
            //document.removeEventListener('touchend', handleTouchEnd);
            resetCursor(node);

            if (hasDragged) {
                e.preventDefault();
                e.stopPropagation();

                // Prevent any subsequent click events from bubbling
                const preventClickPropagation = (clickEvent) => {
                    clickEvent.preventDefault();
                    clickEvent.stopPropagation();
                    document.removeEventListener('click', preventClickPropagation, true);
                };

                document.addEventListener('click', preventClickPropagation, true);

                setTimeout(() => {
                    setIsDragging(false);
                }, 100);
            }
        };

        document.addEventListener('touchmove', handleTouchMove);
        //document.addEventListener('touchend', handleTouchEnd);
    }, [node]);

    const updateCursor = (ele) => {
        ele.style.cursor = 'grabbing';
        ele.style.userSelect = 'none';
    };

    const resetCursor = (ele) => {
        ele.style.cursor = 'grab';
        ele.style.removeProperty('user-select');
    };

    useEffect(() => {
        if (!node) {
            return;
        }

        // Set initial cursor
        node.style.cursor = 'grab';

        node.addEventListener("mousedown", handleMouseDown);
        //node.addEventListener("touchstart", handleTouchStart, { passive: false });

        return () => {
            node.removeEventListener("mousedown", handleMouseDown);
            //node.removeEventListener("touchstart", handleTouchStart);
        };
    }, [node, handleMouseDown, handleTouchStart]);

    return [ref, isDragging];
};
