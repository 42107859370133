// UserActionsContext.js
import React, { createContext, useContext, useState, useCallback, useEffect } from 'react';

// Create the context
const UserActionsContext = createContext();

// Local storage key
const STORAGE_KEY = 'userActionCounts';

// Helper functions for localStorage
const getStoredCounts = () => {
    try {
        const storedCounts = localStorage.getItem(STORAGE_KEY);
        return storedCounts ? JSON.parse(storedCounts) : { getDealClick: 0, visitCount: 0 };
    } catch (error) {
        console.error('Error retrieving counts from localStorage:', error);
        return { getDealClick: 0 };
    }
};

const storeCounts = (counts) => {
    try {
        localStorage.setItem(STORAGE_KEY, JSON.stringify(counts));
    } catch (error) {
        console.error('Error storing counts in localStorage:', error);
    }
};

// Create a provider component
export const UserActionsProvider = ({ children }) => {
    // State to track actions count, initialized from localStorage
    const [actionCounts, setActionCounts] = useState(getStoredCounts);

    // Update localStorage whenever actionCounts changes
    useEffect(() => {
        storeCounts(actionCounts);
    }, [actionCounts]);

    // Function to track getDealClick action
    const trackGetDealClick = useCallback(() => {
        setActionCounts(prevCounts => ({
            ...prevCounts,
            getDealClick: prevCounts.getDealClick + 1,
        }));
    }, [])

    // Function to track visitCount action
    const trackVisitCount = useCallback(() => {
        setActionCounts(prevCounts => ({
            ...prevCounts,
            visitCount: prevCounts.visitCount + 1,
        }));
    }, [])



    // Function to reset all counts
    const resetCounts = useCallback(() => {
        const initialCounts = {
            getDealClick: 0,
            visitCount: 0,
        };
        setActionCounts(initialCounts);
        storeCounts(initialCounts);
    }, []);

    // Value object to be provided by the context
    const value = {
        actionCounts,
        trackGetDealClick,
        trackVisitCount,
        resetCounts,
    };

    return (
        <UserActionsContext.Provider value={value}>
            {children}
        </UserActionsContext.Provider>
    );
};

// Custom hook for using the context
export const useUserActions = () => {
    const context = useContext(UserActionsContext);
    if (context === undefined) {
        throw new Error('useUserActions must be used within a UserActionsProvider');
    }
    return context;
};
