import React, { createContext, useState, useContext, useEffect } from 'react';

// Create the context
const PWAInstallContext = createContext();

// Custom hook for using the context
export const usePWAInstall = () => useContext(PWAInstallContext);

// Provider component
export const PWAInstallProvider = ({ children }) => {
    const debug = false;
    const [installPrompt, setInstallPrompt] = useState(null);
    const [isInstalled, setIsInstalled] = useState(false);
    const [userChoice, setUserChoice] = useState(null);
    const [isIOS, setIsIOS] = useState(false);
    const [isSafari, setIsSafari] = useState(false);
    const [isStandalone, setIsStandalone] = useState(false);

    // Detect platform and installation status on mount
    useEffect(() => {
        // Check if it's iOS
        const ios = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
        setIsIOS(ios);

        // Check if it's Safari
        const safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        setIsSafari(safari);

        // Check if already installed / in standalone mode
        const standalone = window.matchMedia('(display-mode: standalone)').matches ||
                           window.navigator.standalone === true;
        setIsStandalone(standalone);
        setIsInstalled(standalone);

        // For non-iOS devices, listen for the beforeinstallprompt event
        if (!ios) {
            if(debug){
                event = new Event('beforeinstallprompt')
            }

            const handleBeforeInstallPrompt = (e) => {
                console.log('Before Install Prompt Triggered');
                // Prevent the default browser prompt
                e.preventDefault();
                // Save the event for later use
                setInstallPrompt(e);
            };

            window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
            window.addEventListener('appinstalled', () => {
                setIsInstalled(true);
                setInstallPrompt(null);
            });

            if(debug){
                window.dispatchEvent(event)
            }

            return () => {
                window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
                window.removeEventListener('appinstalled', () => {
                    setIsInstalled(true);
                    setInstallPrompt(null);
                });
            };
        }
    }, []);

    // Function to trigger the install prompt for non-iOS
    const promptToInstall = async () => {
        if (isIOS) {
            // Can't programmatically prompt on iOS
            return "ios_manual";
        }

        if (!installPrompt) {
            console.log('Install prompt not available');
            return "unavailable";
        }

        // Show the install prompt for non-iOS devices
        installPrompt.prompt();

        // Wait for the user to respond to the prompt
        const choiceResult = await installPrompt.userChoice;
        setUserChoice(choiceResult.outcome);

        console.log(`User response to the install prompt: ${choiceResult.outcome}`);

        // Clear the saved prompt since it can only be used once
        setInstallPrompt(null);

        return choiceResult.outcome;
    };

    // Check if install is available
    const isInstallAvailable = !!installPrompt || (isIOS && isSafari && !isStandalone);

   console.log('Install prompt available', isInstallAvailable);
    // iOS-specific instructions for manual install
    const getIOSInstallInstructions = () => {
        if (!isIOS) return null;

        return {
            title: "Install on iOS",
            steps: [
                "Scroll down and tap 'Add to Home Screen'",
                "Tap 'Add' in the top-right corner"
            ]
        };
    };

    // Value object to be provided by the context
    const value = {
        isInstalled,
        isInstallAvailable,
        promptToInstall,
        userChoice,
        isIOS,
        isSafari,
        isStandalone,
        getIOSInstallInstructions
    };

    return (
        <PWAInstallContext.Provider value={value}>
            {children}
        </PWAInstallContext.Provider>
    );
};
